/**
 * Create Mollie payment for PDEV payment and redirect to Mollie pay page
 * @param payment_track_id
 * @param method
 * @returns {boolean}
 */
function createMolliePayment(payment_track_id, method)
{
    if(!method || method === 0) {
        return false;
    }
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_PaymentScreen_url,
        data: {option: 'createPaymentMolliePayment', method:method, payment:payment_track_id},
        success: function (data)
        {
            if(parseInt(data['error']) === 1) {
                returnNotification("Oeps", data['msg'], 'danger');
            }
            else {
                redirectToPage(data['mollie_url']);
            }
        }
    });
    return true;
}
$( document ).ready(function()
{
    activateCustomerEvents();
});

/**
 * Activate customer events
 */
function activateCustomerEvents()
{
    //Login customer
    //Selector: cs-login
    $('*[data-pdev-action="cs-login"]').on('click', function()
    {
        loginCustomer();
    });

    //Logout customer
    //Selector: cs-logout
    $('*[data-pdev-action="cs-logout"]').on('click', function()
    {
        logoutCustomer();
    });
}


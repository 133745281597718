/**
 * Page view additional data
 */
function PVAD()
{
    var pvi = $('#PVI').val();
    if(pvi)
    {
        $.ajax
        ({
            type: 'POST',
            dataType: 'json',
            url: front_CMS_url,
            data: {option:'pvad', pvi:pvi, w:screen.width, h:screen.height}
        });
    }
}

/**
 * Send contact form
 * @returns {boolean}
 */
function sendContactForm()
{
    var form = $('form[data-pdev-form="cms-contact-form"]').serialize();
    if(!form)
    {
        returnNotification("Oeps", 'Juiste formulier niet gevonden.', 'danger');
        return false;
    }
    form += "&option=sendContactFormMail";
    var response = $('div[data-pdev-action="cms-contact-form-response"]');
    $.ajax
    ({
        type: 'POST',
        dataType: 'json',
        url: front_CMS_url,
        data: form,
        success: function (data)
        {
            if(response)
            {
                response.html(data['msg']);
            }
            else if(parseInt(data['error']) === 1)
            {
                returnNotification("Oeps", data['msg'], 'danger');
            }
            else
            {
                returnNotification("Gelukt", data['msg'], 'success');
            }
        }
    });
}
function returnNotification(title, message, type = "info", fontAwesomeIcon = "", url = "", verticalAlignment = "top", horizontalAlignment = "right", progressbar = false)
{
    if(title)
    {
        if(message)
        {
            title += ":";
        }
        title = "<strong>" + title + "</strong>";
    }
    $.notify
    ({
        icon: fontAwesomeIcon,
        title: title,
        message: message,
        url: url
    },
    {
        element: 'body',
        position: null,
        type: type,
        showProgressbar: progressbar,
        icon_type: 'class',
        allow_dismiss: true,
        placement:
        {
            from: verticalAlignment,
            align: horizontalAlignment
        },
        delay: 3500,
        timer: 1000,
        url_target: '_blank',
        animate:
        {
            enter: 'animated fadeInDown',
            exit: 'animated fadeOutUp'
        },
        onShow: null,
        onShown: null,
        onClose: null,
        onClosed: null,
        mouse_over: "pause",
    });
}

$( document ).ready(function()
{
    // Database error message
    if(typeof db_error_message !== 'undefined') {
        if(db_error_message) {
            db_error_title = 'Database Error';
            if(typeof db_error_message_title !== 'undefined') {
                db_error_title = db_error_message_title;
            }
            db_error_color = 'danger';
            if(typeof db_error_message_color !== 'undefined') {
                db_error_color = db_error_message_color;
            }
            returnNotification(db_error_title, db_error_message, db_error_color);
        }
    }

    //Other error messages
    if(typeof error_message !== 'undefined') {
        if(error_message) {
            error_title = 'Error';
            if(typeof error_message_title !== 'undefined') {
                error_title = error_message_title;
            }
            error_color = 'danger';
            if(typeof error_message_color !== 'undefined') {
                error_color = error_message_color;
            }
            returnNotification(error_title, error_message, error_color);
        }
    }

    //Warning messages
    if(typeof warning_message !== 'undefined') {
        if(warning_message) {
            warning_title = '';
            if(typeof warning_message_title !== 'undefined') {
                warning_title = warning_message_title;
            }
            warning_color = 'warning';
            if(typeof warning_message_color !== 'undefined') {
                warning_color = warning_message_color;
            }
            returnNotification(warning_title, warning_message, warning_color);
        }
    }

    //Success messages
    if(typeof success_message !== 'undefined') {
        if(success_message) {
            success_title = '';
            if(typeof success_message_title !== 'undefined') {
                success_title = success_message_title;
            }
            success_color = 'success';
            if(typeof success_message_color !== 'undefined') {
                success_color = success_message_color;
            }
            returnNotification(success_title, success_message, success_color);
        }
    }

    //Info messages
    if(typeof info_message !== 'undefined') {
        if(info_message) {
            info_title = '';
            if(typeof info_message_title !== 'undefined') {
                info_title = info_message_title;
            }
            info_color = 'info';
            if(typeof info_message_color !== 'undefined') {
                info_color = info_message_color;
            }
            returnNotification(info_title, info_message, info_color);
        }
    }
});

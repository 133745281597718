/**
 * refresh cart content
 */
function cartContent()
{
    return new Promise(async (resolve, reject) => {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'cartContent'},
            success: function(data)
            {
                $('*[data-pdev-shopping-cart-content]').replaceWith(data);
                activateWebshopEvents();
                resolve(data);
            }
        });
    });
}
$(document).ready(function() {
    activatePaymentScreenEvents();
});

/**
 * Payment screen events
 */
function activatePaymentScreenEvents()
{
    //Get elements
    const methodSelect = $('*[data-pdev-action="payment-method-change"]');
    const startPaymentBtn = $('*[data-pdev-action="payment-start-button"]');

    /**
     *  Payment method select change event
     */
    methodSelect.on('change', function() {
        //Hide all payment info
        $('*[data-pdev-payment-method-info]').hide();

        //Hide & disable start payment button
        startPaymentBtn.hide();

        //Show mollie info & start payment button
        if(parseInt($(this).find(':selected').data('pdev-mollie')) === 1) {
            $('*[data-pdev-payment-method-info="mollie"]').show();
            startPaymentBtn.show();
        }
        //Show other payment method info
        else {
            $('*[data-pdev-payment-method-info="' + this.value + '"]').show();
        }
    });

    /**
     *  Create mollie payment
     */
    startPaymentBtn.on('click', function()
    {
        //Get track id
        const track_id = $(this).attr("data-pdev-track-id");
        if(!track_id || track_id === '') {
            returnNotification("", 'Geen betaling ID beschikbaar.', 'warning');
            $("*[data-pdev-payment-error]").html("Geen betaling ID beschikbaar.");
            return false;
        }

        //Get payment method
        const method = methodSelect.val();
        if(!method || parseInt(method) === 0) {
            returnNotification("", 'Selecteer een betaalmethode.', 'warning');
            $("*[data-pdev-payment-error]").html("Selecteer een betaalmethode.");
            return false;
        }

        //Create Mollie payment
        if(!createMolliePayment(track_id, method)) {
            returnNotification("", 'Er ging iets mis, probeer het opnieuw.', 'danger');
        }
    });
}


/**
 * Login customer
 */
function loginCustomer()
{
    const email = $('input[data-pdev-cs-email]').val();
    const password = $('input[data-pdev-cs-password]').val();

    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Customer_url,
        data: {option:'loginCustomer', email:email, password:password},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                setTimeout(function() {
                    redirectToPage(data['redirect']);
                }, 150);
            }
            else
            {
                returnNotification("Oeps", data['msg'], "danger");
            }
        }
    });
}

/**
 * Logout customer
 */
function logoutCustomer()
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Customer_url,
        data: {option:'logoutCustomer'},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                redirectToPage("/");
            }
        }
    });
}


//Actions are used by PDEV_Webshop_events.js
//Use data-pdev- attributes

/**
 * Add product to shopping cart
 * @param pid
 * @returns {Promise<unknown>}
 */
function addToCart(pid)
{
    //Get amount, default 1
    let amount = $('input[data-pdev-cart-amount=' + pid + ']').val();
    amount = (!amount ? 1 : amount);

    //Get product note, default null
    let note = $('textarea[data-pdev-cart-note=' + pid + ']').val();
    note = (!note ? null : note);

    //Add product to cart
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'addToCart', pid:pid, amount:amount, note:note},
            success: function(data)
            {
                return resolve(data);
            }
        });
    });
}

/**
 * Update product amount in shopping cart
 * @param cart_key
 * @param pid
 * @param amount
 * @returns {Promise<unknown>}
 */
function updateCartAmount(cart_key, pid, amount)
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'updateCartAmount', pid:pid, cart_key:cart_key, amount:amount},
            success: function(data)
            {
                resolve(data);
            }
        });
    });
}

/**
 * Delete product from shopping cart
 * @param cart_key
 * @param pid
 */
function deleteFromCart(cart_key, pid)
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'deleteFromCart', cart_key:cart_key, pid:pid},
            success: function(data)
            {
                resolve(data);
            }
        })
    });
}

/**
 * Add coupon
 */
function addCouponCart()
{
    let code = $('input[data-pdev-coupon-code]').val();
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'addCouponCode', code:code},
            success: function(data)
            {
                resolve(data);
            }
        })
    });
}

/**
 * Remove used coupon
 */
function removeCouponCart()
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'removeCouponCode'},
            success: function(data)
            {
                resolve(data);
            }
        })
    });
}

/**
 * Get shopping cart data
 * @returns {Promise<unknown>}
 */
function cartData()
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_shoppingcart_url,
            data: {option:'cartData'},
            success: function(data)
            {
                resolve(data);
            }
        })
    });
}

/**
 * Get and set totals by data attributes
 * @returns {Promise<void>}
 */
async function setCartTotals()
{
    return new Promise(async (resolve, reject) => {
        const totals = await cartData();
        $('[data-pdev-cart-total]').html(moneyFormat(totals.CartTotal));
        $('[data-pdev-cart-total-ex-shipping]').html(moneyFormat(totals.CartTotalExShipping));
        $('[data-pdev-cart-total-ex-shipping-vat]').html(moneyFormat(totals.CartTotalExShippingVat));
        $('[data-pdev-cart-discount]').html(moneyFormat(totals.CartDiscount));
        $('[data-pdev-cart-coupon-discount]').html(moneyFormat(totals.CouponDiscount));
        $('[data-pdev-cart-product-count]').html(parseInt(totals.CartProductCount));
        resolve(totals);
    });
}

/**
 * Change shipping method
 * @param shipping_method
 */
function changeShippingMethod(shipping_method)
{
    return new Promise(async (resolve, reject) => {
        $.ajax
        ({
            type: "POST",
            dataType: 'json',
            url: front_Webshop_shop_url,
            data: {option:'changeShipping', method:shipping_method},
            success: function(data)
            {
                resolve(data);
            }
        })
    });
}

/**
 * Create new order from checkout page
 * @returns {boolean}
 */
function createOrder()
{
    //Order button action selectors
    const order_btn = $('*[data-pdev-action="ws-create-order"]');
    const order_btn_disable = $('*[data-pdev-action="ws-create-order-button-disable"]');
    const order_btn_hide = $('*[data-pdev-action="ws-create-order-button-hide"]');
    const order_btn_show = $('*[data-pdev-action="ws-create-order-button-show"]');

    //Order button start actions
    order_btn.prop("disabled", true);
    order_btn_disable.prop("disabled", true);
    order_btn_hide.hide();
    order_btn_show.show();

    //Remove old checkout message
    $('*[data-pdev-ws-checkout-msg]').html('');

    //Get checkout form
    const checkout_form = $('form[data-pdev-ws-checkout-form]').serialize();

    //Get payment method
    let payment_method;
    const paymentElement = $('[data-pdev-payment-method]');
    if(paymentElement[0].type === 'radio') {
        payment_method = $('input[data-pdev-payment-method]:checked').val();
    }
    else {
        payment_method =paymentElement.val();
    }

    //Stop if no payment method was selected
    if(payment_method === undefined) {
        returnNotification("", "Selecteer een betaalmethode", "warning");
        order_btn.prop("disabled", false);

        //Reset order button actions
        order_btn.prop("disabled", false);
        order_btn_disable.prop("disabled", false);
        order_btn_hide.show();
        order_btn_show.hide();
        return false;
    }

    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_url,
        data: checkout_form + '&option=createOrder&payment_method=' + payment_method,
        success: function(data)
        {
            if(parseInt(data['error']) === 1) {
                $('*[data-pdev-ws-checkout-msg]').html(data['msg']);

                //Reset order button actions
                order_btn.prop("disabled", false);
                order_btn_disable.prop("disabled", false);
                order_btn_hide.show();
                order_btn_show.hide();
                return false;
            }
            order_btn_show.hide();
            returnNotification("", data['msg'], "success");
            createOrderPayment(data['track_id']);
        }
    });
}

/**
 * Create order payment and redirect to payment page
 * @param track_id
 */
function createOrderPayment(track_id)
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_url,
        data: {option:'createOrderPayment', track_id:track_id},
        success: function(data)
        {
            if(parseInt(data['error']) === 1) {
                returnNotification("Oeps", data['msg'], "danger");
                return false;
            }
            if(parseInt(data['error']) === 2) {
                returnNotification("Oeps", data['msg'], "warning");
                $('*[data-pdev-ws-checkout-msg]').html(data['msg']);
                return false;
            }
            returnNotification("", data['msg'], "success");
            setTimeout(function (){
                window.location = data['redirect'];
            }, 3000);
        }
    });
}
$( document ).ready(function()
{
    PVAD();

    //Send contact form
    //Selector: cms-send-contact-form
    $('*[data-pdev-action="cms-send-contact-form"]').on('click', function()
    {
        sendContactForm();
    });
});


/*****************************************
 * Basic usages functions  for web-front
 *****************************************/

/**
 * Redirect to page
 * @param page
 */
function redirectToPage(page)
{
    window.location.replace(page);
}